import React from 'react';
import Container from "react-bootstrap/Container";
import {Col, Row, Image, Button} from "react-bootstrap";
import AdvertImg from '../../../assets/img/IMG-20220803-WA0015.jpg'

const AdvertSection = () => {
	return (
		<Container className="AdvertSectionpy-5 py-5 mb-5">
			<Row className="align-items-center text-center text-lg-start">
				<Col md={12} lg={5} className="mb-5 mb-lg-0">
					<Image
						src={AdvertImg}
						alt="join us image"
						fluid
					/>
				</Col>
				<Col md={12} lg={7} className="">
					<p className="display-2 fw-bold text-orange text-curl">Bringing internet to all areas of Kenya</p>
					<p className="fs-4 fw-semibold mb-3 mb-lg-5">We believe EVERYONE deserves access to the internet and
						affordable communications.</p>
					<p className="fs-5">Communications have an incredibly positive impact on improving the lives of individuals
						and accelerating the economic and social growth of developing countries, and yet 4.4 billion people around
						the world are still unconnected and many more are poorly serviced.
					</p>
				</Col>
			</Row>
		</Container>
	);
};

export default AdvertSection;
