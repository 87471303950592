import React from 'react';
import {Card, Col, Row, Image} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./index.css"

const {Body, Img, Title, Text} = Card

interface ServicesProps {
	title: string
	subTitle: string
	data: {
		title: string
		description: string
		imageUrl: string
	}[]
}

const ServicesSection = ({title, subTitle, data}: ServicesProps) => {
	return (
		<Container className="ServicesSection py-5 mb-5">
			<h5 className="display-2 fw-bold text-center text-capitalize text-orange text-curl">{title}</h5>
			<p className="fs-5 text-center fw-semibold mb-3 mb-lg-5 text-capitalize">{subTitle}</p>
			<Row xs={1} md={2} lg={3} className="g-4">
				{data.map((d, idx: number) => (
					<Col key={`services-${idx}`} className="text-start">
						<Card className="h-100">
							<Body>
								<Image src={d.imageUrl} width={60} height={60} alt={`${d.title} icon`} className="mb-3"/>
								<Title>{d.title}</Title>
								<Text>{d.description}</Text>
							</Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default ServicesSection;
