import React from 'react';
import './index.css'
import {Container} from "react-bootstrap";

const HeroSection = () => {
	return (
		<div className="d-flex flex-column justify-content-center py-5 mb-5 bg-orange">
			<Container>
				<p className="display-5 fw-bold text-white text-center">Contact us</p>
			</Container>
		</div>
	);
};

export default HeroSection;
