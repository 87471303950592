import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";

interface TestimonialsProps {
	data: {
		remark: string
		user?: string
		position?: string
	}[]
}

const Testimonials = ({data}: TestimonialsProps) => {
	return (
		<Container className="py-5 mb-5">
			<p className="display-2 fw-bold mb-3 mb-lg-5 text-center text-orange text-curl">What our customers are saying</p>
			<Row md={1} lg={2} className="mt-5 order-3 mx-auto g-4">
				{data.map((d, idx) =>
					<Col key={`testimonial-${idx}`}>
						<Card className="h-100 bg-transparent">
							<Card.Body>
								<Card.Text>"{d.remark}"</Card.Text>
								<Card.Text><i className="bi-person-circle me-2"/>{d.user ? `${d.user},` : "anonymous,"}
									<span>&nbsp;{d.position}</span></Card.Text>
							</Card.Body>
						</Card>
					</Col>
				)}
			</Row>
		</Container>
	);
};

export default Testimonials;
