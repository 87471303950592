import React from 'react';
import {Button, Container} from 'react-bootstrap';
import './index.css'

interface FooterProps {
	socials: {
		icon: string
		title: string
		link: string
	}[]
}

const SocialsSection = ({socials}: FooterProps) => {
	return (
		<div className="SocialsSection py-5 mb-5">
			<Container>
				<p className="display-2 fw-bold text-center mb-3 mb-lg-5 text-orange text-curl">Our social media handles</p>
				<div className="d-flex justify-content-center gap-3">
					{socials.map(social =>
						<Button size="lg" variant="dark" href={social.link} target="_blank">
							<i className={social.icon}/>
							<span className="ms-2">{social.title}</span>
						</Button>
					)}
				</div>
			</Container>
		</div>
	);
};

export default SocialsSection;
