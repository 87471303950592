import React from 'react';
import {Container} from 'react-bootstrap';

const LowerPriceSection = () => {
	return (
		<div className="h-100 d-flex flex-column justify-content-center text-center py-5 mb-5">
			<Container>
				<p className="display-2 fw-bold mb-3 mb-lg-5 text-orange text-capitalize text-curl">we offer the lowest price to get
					connected</p>
				<p className="fs-5 mb-3 mb-lg-5">Signup for unlimited network for only <b>KSh 3000!</b> Then you only
					Pay <b>KSh 1500</b> per month for UNLIMITED internet, which means you can download, and stream 24/7</p>
			</Container>
		</div>
	);
};

export default LowerPriceSection;
