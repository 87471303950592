import React from 'react';
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import {AdvertSection, HeroSection, MoreInfoSection, SocialsSection, TestimonialsSection} from '../components/AboutUs';
import {Helmet} from "react-helmet";
import {Socials} from "../data/socials";
import {Testimonials} from "../data/testimonials";

const AboutUs = () => {
	return (
		<div>
			<Helmet>
				<title>M-network - About us</title>
			</Helmet>
			<TopNav/>
			<HeroSection/>
			<AdvertSection/>
			<MoreInfoSection/>
			<TestimonialsSection data={Testimonials.data}/>
			<hr className="my-3"/>
			<SocialsSection socials={Socials.data}/>
			<Footer socials={Socials.data}/>
		</div>
	);
};

export default AboutUs;
