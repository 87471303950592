import React from 'react';
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap';

const ContactFormSection = () => {
	return (
		<Container className="py-5 mb-5">
			<p className="display-2 fw-bold text-center text-capitalize text-orange text-curl">Tell us about yourself</p>
			<p className="fs-5 fw-semibold text-center mb-3 mb-lg-5 text-capitalize">Whether you have questions or you would
				just like to say hello, contact us.</p>
			<Row>
				<Col lg={4}>
					<Card className="bg-transparent mb-3">
						<Card.Body>
							<Card.Title>Contact info</Card.Title>
							<Card.Text>Email:
								<a href='mailto:info@mnetwork.co.ke' className="ms-1">info@mnetworks.co.ke</a>
							</Card.Text>
							<Card.Text>Phone: 0719-835-499</Card.Text>
							<Card.Text>Phone: 0713-771-351</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={8}>
					<Card className="bg-transparent">
						<Card.Body>
							<Card.Title>Contact form</Card.Title>
							<Form>
								<Form.Group className="mb-3" controlId="formGridEmail">
									<Form.Label>Full name</Form.Label>
									<Form.Control type="text" placeholder="Enter your full name"/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formGridPassword">
									<Form.Label>Email address</Form.Label>
									<Form.Control type="email" placeholder="Enter your email address"/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formGridAddress1">
									<Form.Label>Phone number</Form.Label>
									<Form.Control type="tel" placeholder="0000-000-000"/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formGridAddress2">
									<Form.Label>Company</Form.Label>
									<Form.Control type="text" placeholder="Enter your company"/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formGridAddress2">
									<Form.Label>Subject</Form.Label>
									<Form.Control type="text" placeholder="Enter your subject"/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formGridAddress2">
									<Form.Label>Message</Form.Label>
									<Form.Control type="text" as="textarea" placeholder="Enter your message" rows={3}/>
								</Form.Group>

								<Button className="btn-orange" type="submit">
									Send message
									<i className="bi-send ms-2"></i>
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ContactFormSection;
