import React from 'react';
import './index.css'
import {Button, Col, Image, Row} from "react-bootstrap";
import LogoImg from "../../assets/img/wifi-logo.png";
import Container from "react-bootstrap/Container";

interface FooterProps {
	socials: {
		icon: string
		link: string
	}[]
}

const Footer = ({socials}: FooterProps) => {
	return (
		<div className="FooterSection bg-dark">
			<Container>
				<div className="py-5">
					<Row>
						<Col md={12} lg={4} className="mb-3">
							<div className="d-flex align-items-center mb-3">
								<Image src={LogoImg} height={40} width={40} fluid className="me-2"/>
								<p className="h4 mb-0">M-Network</p>
							</div>
							<div className="d-flex gap-2">
								{socials.map(social =>
									<Button variant="dark" href={social.link} target="_blank">
										<i className={social.icon}/>
									</Button>)}
							</div>
						</Col>
						<Col md={12} lg={2} className="mb-3">
							<p className="h5 mb-3">Navigation</p>
							<ul className="nav flex-column">
								<li className="nav-item mb-2"><a href="/" className="nav-link p-0">Home</a></li>
								<li className="nav-item mb-2"><a href="/about-us" className="nav-link p-0">About Us</a></li>
								<li className="nav-item mb-2"><a href="/our-coverage" className="nav-link p-0">Our Coverage</a></li>
								<li className="nav-item mb-2"><a href="/services" className="nav-link p-0">Services</a></li>
								<li className="nav-item mb-2"><a href="#" className="nav-link p-0">Our Products</a></li>
								<li className="nav-item mb-2"><a href="/contact" className="nav-link p-0">Contact Us</a></li>
							</ul>
						</Col>
						<Col md={12} lg={2} className="mb-3">
							<p className="h5 mb-3">Reach out</p>
							<ul className="nav flex-column">
								<li className="nav-item mb-2">
									<a href="mailto:info@mnetwork.co.ke" className="nav-link p-0">
										<i className="bi-envelope-fill me-1"/>
										<span>info@mnetwork.co.ke</span>
									</a>
								</li>
								<li className="nav-item mb-2">
									<a href="#" className="nav-link p-0">
										<i className="bi-telephone-fill me-1"/>
										<span>0719-835-499</span>
									</a>
								</li>
								<li className="nav-item mb-2">
									<a href="#" className="nav-link p-0">
										<i className="bi-telephone-fill me-1"/>
										<span>0713-771-351</span>
									</a>
								</li>
							</ul>
						</Col>
						<Col md={12} lg={2} className="mb-3">
							<p className="h5 mb-3">Resources</p>
							<ul className="nav flex-column">
								<li className="nav-item mb-2">
									<a href="#" className="nav-link p-0">
										Terms of Service
									</a>
								</li>
								<li className="nav-item mb-2">
									<a href="#" className="nav-link p-0">
										Privacy Policy
									</a>
								</li>
								<li className="nav-item mb-2">
									<a href="#" className="nav-link p-0">
										Help
									</a>
								</li>
							</ul>
						</Col>
					</Row>
					<div className="pt-3 pt-lg-4 border-top text-center">
						<p>© 2022 M-networks. All rights reserved.</p>
					</div>
				</div>
			</Container>
		</div>
	)
};

export default Footer;
