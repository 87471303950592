import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Route, Routes} from 'react-router-dom';
import {Action, Fab} from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {AboutUsPage, ContactPage, CoveragePage, HomePage, ServicesPage} from './pages';
import './App.css'

const mainButtonStyles: React.CSSProperties = {
	background: "#008083",
}

const App = () => {
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<HomePage/>}/>
				<Route path="/our-coverage" element={<CoveragePage/>}/>
				<Route path="/about-us" element={<AboutUsPage/>}/>
				<Route path="/services" element={<ServicesPage/>}/>
				<Route path="/contact" element={<ContactPage/>}/>
			</Routes>
			<Fab
				mainButtonStyles={mainButtonStyles}
				icon={<i className="bi-plus"/>}
				text="more">
				<Action
					text="Email"
					onClick={() => window.open('mailto:info@mnetwork.co.ke') }
					style={{backgroundColor: "#249EA0"}}
				>
					<i className="bi-envelope-fill"/>
				</Action>
				<Action
					text="Help"
					onClick={() => console.log('')}
					style={{backgroundColor: "#249EA0"}}
				>
					<i className="bi-question"/>
				</Action>
			</Fab>
		</div>
	);
}

export default App;
