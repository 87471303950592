import React from 'react';
import {Button, Container} from 'react-bootstrap';
import './index.css'

const ContactSection = () => {
	return (
		<div className="ContactSection h-100 d-flex flex-column justify-content-center text-white text-center py-5">
			<Container>
				<p className="display-2 fw-bold text-curl">Would you like m-net! to come to your home?</p>
				<p className="fs-5 mb-3 mb-lg-5">We are continually broadening our coverage across Kenya and in the areas around Nairobi. If
					you would like to find out more about the areas we serve, please click the link below.</p>
				<Button variant="light">check our coverage</Button>
			</Container>
		</div>
	);
};

export default ContactSection;
