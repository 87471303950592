import React from 'react';
import {Services} from "../data/services";
import {Pricing} from '../data/pricing';
import {Testimonials} from "../data/testimonials";
import TopNav from '../components/TopNav';
import {
	ContactSection,
	CoverageSection,
	HeroSection,
	JoinUsSection,
	LowerPriceSection,
	PricingSection,
} from '../components/Home';
import Footer from '../components/Footer';
import {Helmet} from 'react-helmet'
import {Socials} from "../data/socials";

const Home = () => {
	return (
		<div>
			<Helmet>
				<title>M-network - Home</title>
			</Helmet>
			<TopNav/>
			<HeroSection/>
			<JoinUsSection/>
			<CoverageSection/>
			<LowerPriceSection/>
			<hr className="my-3"/>
			<PricingSection title={Pricing.title} subTitle={Pricing.subTitle} data={Pricing.data}/>
			<ContactSection/>
			<Footer socials={Socials.data}/>
		</div>
	);
};

export default Home;
