import React from 'react';
import {Helmet} from "react-helmet";
import {HeroSection} from '../components/Services';
import TopNav from "../components/TopNav";
import {Socials} from "../data/socials";
import Footer from "../components/Footer";
import ContactFormSection from "../components/ContactForm";

const Contact = () => {
	return (
		<div>
			<Helmet>
				<title>M-network - Contact</title>
			</Helmet>
			<TopNav/>
			<HeroSection/>
			<ContactFormSection/>
			<Footer socials={Socials.data}/>
		</div>
	);
};

export default Contact;
