import React from 'react';
import Container from "react-bootstrap/Container";
import {Button, Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";

interface PricingProps {
	title: string
	subTitle: string
	data: {
		package: string
		pricing: string
		features: string[]
		popular: boolean
	}[]
}

const PricingSection = ({title, subTitle, data}: PricingProps) => {
	return (
		<Container className="py-5 mb-5">
			<h1 className="display-2 fw-bold text-center mb-3 mb-lg-4 text-capitalize text-orange text-curl">{title}</h1>
			<h5 className="display-6 fw-semibold text-center mb-3 mb-lg-5 text-capitalize">{subTitle}</h5>
			<Row md={1} lg={3} className="g-4 mb-3 mb-lg-5">
				{data.map((d, idx) =>
					<Col key={`pricing-${idx}`}>
						<Card className={d.popular ? 'border-dark bg-light' : 'border h-100'}>
							<Card.Body>
								<Card.Subtitle className="fs-5 text-center text-uppercase mt-3">{d.package}</Card.Subtitle>
								<Card.Title className="fs-2 text-center my-4">{d.pricing}</Card.Title>
								<ListGroup variant="flush">
									{d.features.map((feature, idx) =>
										<ListGroupItem key={`feature-${idx}`} className="fw-normal text-capitalize bg-transparent">
											<i className="bi-check-circle me-1"/>{feature}
										</ListGroupItem>
									)}
								</ListGroup>
							</Card.Body>
							<Card.Footer className="text-center bg-transparent border-0">
								<p className="fs-6 fw-semibold mb-1">1 free month installation @ KSh 4500</p>
								<p className="">valid for 30 days</p>
								<Button className="mb-2 btn-orange">get connected</Button>
							</Card.Footer>
						</Card>
					</Col>
				)}
			</Row>
			<Card className="col-12 col-lg-6 mx-auto bg-dark text-white">
				<Card.Body>
					<Card.Title className="fs-5 text-capitalize">customized package</Card.Title>
					<Card.Text>We offer variable pricing with discounts for larger organizations. Get in touch with us and
						we’ll figure out something that works for everyone.</Card.Text>
					<Button href="/contact" className="btn-orange">contact us<i className="bi-chevron-right ms-1"/></Button>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default PricingSection;
