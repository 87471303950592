import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Image} from 'react-bootstrap';
import LogoImg from '../../assets/img/wifi-logo.png'
import {useLocation} from "react-router-dom";
import './index.css'

const TopNav = () => {
	const location = useLocation()

	const urlResolver = (href: string) => {
		return location.pathname === href
	}

	return (
		<Navbar expand="lg" className="sticky-top shadow-sm">
			<Container className="py-2">
				<Navbar.Brand href="/">
					<Image src={LogoImg} height={40} width={40} fluid className="me-2"/>
					<span className="fw-bold">M-Network</span>
				</Navbar.Brand>
				<Navbar.Toggle className="border-0" aria-controls="basic-navbar-nav" title="menu">
					<i className="bi-menu-button text-white fs-1"></i>
				</Navbar.Toggle>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto">
						<Nav.Link
							className={urlResolver('/') ? 'active' : ''}
							href="/">Home</Nav.Link>
						<Nav.Link
							className={urlResolver('/about-us') ? 'active' : ''}
							href="/about-us">About us</Nav.Link>
						<Nav.Link
							className={urlResolver('/our-coverage') ? 'active' : ''}
							href="/our-coverage">Our coverage</Nav.Link>
						<Nav.Link
							className={urlResolver('/services') ? 'active' : ''}
							href="/services">Services</Nav.Link>
						<NavDropdown title="Our products" id="basic-nav-dropdown">
							<NavDropdown.Item href="#action/3.1">M-net</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link
							className={urlResolver('/contact') ? 'active' : ''}
							href="/contact">Contact us</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default TopNav;
