import React from 'react';
import './index.css'
import {Col, Row, Image, Card} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import HeroImg from "../../../assets/img/IMG-20220803-WA0010.jpg"

const HeroSection = () => {
	return (
		<div className="HeroSection mb-5">
			<Container>
				<Row className="align-items-center h-100 py-5 text-white">
					<Col md={12} lg={6} className="order-2 order-lg-1">
						<p className="display-2 fw-semibold text-center text-lg-start text-capitalize text-curl">nowhere be like
							home!</p>
						<p className="display-5 fw-bold text-center text-lg-start text-capitalize">that’s why we strive to make the
							phrase a dream by connecting your home.</p>
					</Col>
					<Col md={12} lg={6} className="order-1 order-lg-2 mb-5 mb-lg-0">
						<Image
							src={HeroImg}
							alt="hero image" fluid/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default HeroSection;
