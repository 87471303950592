import RemoteImg from '../assets/img/remote.png'
import SelfPbxImg from '../assets/img/self-pbx.png'
import HostedPbxImg from '../assets/img/cloud-network.png'
import BranchImg from '../assets/img/branch-office.png'
import CollabImg from '../assets/img/collaborative.png'
import CrmImg from '../assets/img/crm.png'
import CallImg from '../assets/img/call.png'
import IvrImg from '../assets/img/ivr.png'

export const Services = {
	title: 'our services',
	subTitle: 'what can we do to provide your business',
	data: [
		{
			imageUrl: RemoteImg,
			title: 'Remote Teams',
			description: 'We empower your team and Customer Care Agents to work from every corner of the world.'
		},
		{
			imageUrl: SelfPbxImg,
			title: 'Self-Hosted PBX',
			description: 'We enable your company to run your pbx on the company\'s Data Center Within the company premise'
		},
		{
			imageUrl: HostedPbxImg,
			title: 'Hosted Pbx',
			description: 'Let\'s do everything for you, while you concentrate on what matters in your business'
		},
		{
			imageUrl: BranchImg,
			title: 'Branch Office',
			description: 'Spend less by connecting your Branch offices together irregardless of the company Location.'
		},
		{
			imageUrl: CollabImg,
			title: 'Collaboration',
			description: 'Enable Your Team to collaborate with powerful voice and video conferences tools'
		},
		{
			imageUrl: CrmImg,
			title: 'CRM Integration',
			description: 'Get customer insights,by integrating your CRM and phone system easily'
		},
		{
			imageUrl: CallImg,
			title: 'Call Recording',
			description: 'Get to know how your agents are performing and how your team communicate with clients and customers'
		},
		{
			imageUrl: IvrImg,
			title: 'IVR',
			description: 'Let your callers interact with automated menus, enabling faster resolution time by directing to the right agents'
		}
	]
}
