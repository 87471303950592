import React from 'react';
import Container from "react-bootstrap/Container";
import {Col, Row, Image, Button} from "react-bootstrap";
import AdvertImg from '../../../assets/img/IMG-20220803-WA0016.jpg'

const AdvertSection = () => {
	return (
		<Container className="AdvertSectionpy-5 py-5 mb-5">
			<Row className="align-items-center text-center text-lg-start">
				<Col md={12} lg={6} className="mb-5 mb-lg-0">
					<Image
						src={AdvertImg}
						alt="join us image"
						fluid
					/>
				</Col>
				<Col md={12} lg={6}>
					<p className="display-2 fw-bold text-orange text-curl">We are now live in Uthiru!</p>
					<p className="fs-5 mb-3 mb-lg-5">We are pleased to inform you that we are now live in Uthiru. If you would like
						to be one of the first to get installed, please follow the link below. Look forward to having you onboard
						the Unlimited Network!</p>
					<Button className="btn-orange">sign up for m-net internet</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default AdvertSection;
