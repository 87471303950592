export const Socials = {
	data: [
		{
			title: 'facebook',
			icon: 'bi-facebook',
			link: 'https://www.facebook.com/mnnetworks/'
		},
		{
			title: 'twitter',
			icon: 'bi-twitter',
			link: 'https://twitter.com/mnetwork_help'
		},
	]
}
