import React from 'react';
import './index.css'
import {Col, Row, Image, Container, Button} from "react-bootstrap";
import HeroImg from "../../../assets/img/IMG-20220803-WA0011.jpg"

const HeroSection = () => {
	return (
		<div
			className="HeroSection h-100 d-flex flex-column justify-content-center text-white py-5 mb-5 text-center text-lg-start">
			<Container>
				<Row className="align-items-center text-white">
					<Col md={12} lg={7} className="order-2 order-lg-1">
						<p className="display-2 fw-semibold text-center text-lg-start text-capitalize text-curl">About m-net!</p>
						<p className="display-4 fw-bold text-center text-lg-start text-capitalize">An internet connection to
							office/home?</p>
						<p className="fs-5 mb-3 mb-lg-5">We connect offices and homes to the internet we use a combination of Fiber
							and powerful wireless technology to deliver speed up to 1000 MBPS look for a connection image to use it
							together.</p>
						<Button variant="light" size="lg">Get in touch with us</Button>
					</Col>
					<Col md={12} lg={5} className="order-1 order-lg-2 mb-5 mb-lg-0">
						<Image
							src={HeroImg}
							alt="hero image"
							fluid
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default HeroSection;
