import React from 'react';
import {Helmet} from "react-helmet";
import {HeroSection, ServicesSection} from '../components/Services';
import TopNav from "../components/TopNav";
import { Services } from '../data/services';
import {Socials} from "../data/socials";
import Footer from "../components/Footer";

const Service = () => {
	return (
		<div>
			<Helmet>
				<title>M-network - Services</title>
			</Helmet>
			<TopNav/>
			<HeroSection/>
			<ServicesSection title={Services.title} subTitle={Services.subTitle} data={Services.data}/>
			<Footer socials={Socials.data}/>
		</div>
	);
};

export default Service;
