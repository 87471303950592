export const Pricing = {
	title: 'm-net packages',
	subTitle: 'Choose the package that suits your needs',
	data: [
		{
			package: 'starter',
			pricing: 'KSh 1499',
			features: [
				'up to 5 MBPs',
				'Fast web browsing',
				'SD Movie & music streaming',
				'Internet surfing, social media & email',
			],
			popular: false
		},
		{
			package: 'bronze',
			pricing: 'KSh 1999',
			features: [
				'up to 8 MBPs',
				'Fast web browsing & Video calls',
				'HD TV shows and movies upto 3 connected devices',
				'Internet surfing, social media & email',
				'Moderate streaming',
				'Superfast video downloads',
				'Agent Performance',
				'CCTV devices Capability',
			],
			popular: true
		},
		{
			package: 'gold',
			pricing: 'KSh 2499',
			features: [
				'up to 12 MBPs',
				'Fast web browsing',
				'4K Movies & TV Shows',
				'Online gaming and downloading',
				'Multiple device music streaming',
				'Superfast video downloads',
				'CCTV devices Capability',
			],
			popular: false
		}
	]
}
