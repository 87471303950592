import React from 'react';
import Container from "react-bootstrap/Container";
import {Col, Row, Image, Button} from "react-bootstrap";

const JoinUsSection = () => {
	return (
		<Container className="py-5 mb-5">
			<Row className="align-items-center text-center text-lg-start">
				<Col md={12} lg={6} className="order-2">
					<p className="display-2 fw-semibold text-orange text-curl">Join the Unlimited Network</p>
					<p className="fs-5 mb-3 mb-lg-5">Stream all day, every day!</p>
					<Button className="btn-orange">sign up for m-net internet</Button>
				</Col>
				<Col md={12} lg={6} className="order-1 mb-5 mb-lg-0">
					<Image
						src="https://images.unsplash.com/photo-1517817748493-49ec54a32465?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
						alt="join us image"
						fluid
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default JoinUsSection;
