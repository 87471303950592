import React from 'react';
import './index.css'
import {Col, Row, Image, Container} from "react-bootstrap";
import HeroImg from '../../../assets/img/IMG-20220803-WA0015.jpg'

const HeroSection = () => {
	return (
		<div
			className="HeroSection h-100 d-flex flex-column justify-content-center text-white py-5 mb-5 text-center text-lg-start">
			<Container>
				<Row className="align-items-center text-white">
					<Col md={12} lg={6} className="order-2 order-lg-1">
						<p className="display-2 fw-semibold text-curl">Our Coverage!</p>
						<p className="display-3 fw-bold">Serving the homes of Kenya</p>
					</Col>
					<Col md={12} lg={6} className="order-2 order-lg-1 mb-5 mb-lg-0">
						<Image
							src={HeroImg}
							alt="hero image"
							fluid
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default HeroSection;
