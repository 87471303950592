import React from 'react';
import {Container} from 'react-bootstrap';
import './index.css'

const MoreInfoSection = () => {
	return (
		<div className="MoreInfoSection h-100 d-flex flex-column justify-content-center text-center text-white py-5 mb-5">
			<Container>
				<p className="display-2 fw-bold text-curl">Highly Affordable Internet Access</p>
				<p className="display-6 fw-semibold mb-3 mb-lg-5">Serving the homes, communities and schools.</p>
				<p className="fs-5">At m-net! we believe everyone deserves access to the internet and it should not be limited
					to the privileged few. Our m-net! internet service provides broadband to low income and rural communities
					across East Africa, offering individuals and small businesses highly affordable internet access. m-net! brings
					significant social benefit to the communities we serve by offering free access to digital content including
					educational and health materials as well as generating substantial employment opportunities.</p>
			</Container>
		</div>
	);
};

export default MoreInfoSection;
