import React from 'react';
import {Button, Container} from 'react-bootstrap';
import './index.css'

const CoverageSection = () => {
	return (
		<div className="CoverageSection h-100 d-flex flex-column justify-content-center text-center text-white py-5 mb-5">
			<Container>
				<p className="display-2 fw-bold text-curl">Now available in Uthiru</p>
				<p className="fs-4 fw-semibold">Connecting you to the world</p>
				<p className="fs-5 mb-3 mb-lg-5">Do you live in Uthiru? If so then we're delighted to let you know that m-net!
					internet home
					and street services are now LIVE in your area! Just follow the link below and become on of the first to take
					advantage of our UNLIMITED broadband! All installed in 48 hours!</p>
				<Button variant="light">sign up for m-net internet</Button>
			</Container>
		</div>
	);
};

export default CoverageSection;
