import React from 'react';
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import {AdvertSection, HeroSection} from '../components/Coverage';
import ContactFormSection from "../components/ContactForm";
import {Helmet} from "react-helmet";
import {Socials} from "../data/socials";

const Coverage = () => {
	return (
		<div>
			<Helmet>
				<title>M-network - Coverage</title>
			</Helmet>
			<TopNav/>
			<HeroSection/>
			<AdvertSection/>
			<hr className="my-3"/>
			<ContactFormSection/>
			<Footer socials={Socials.data}/>
		</div>
	);
};

export default Coverage;
