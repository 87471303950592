export const Testimonials = {
	data: [
		{
			remark: "This PBX Solution has ticked all the boxes. Replacing our old outdated PBX has meant we no longer need expensive maintenance contracts to keep the system going, saving us thousands of shillings each year.",
			user: "Alex Kemboi",
			position: "IT Director At Uasin Gishu County"
		},
		{
			remark: "It only took one week to get the system running, and the results of the switch were noticeable right away. The implementation was seamless and we couldn’t have hoped for a better transition",
			user: "Vincent Amugenda",
			position: "IT Manager"
		},
		{
			remark: "Mnet Internet is the best thing that could have ever to happened to me. My house was located In a place where getting an unlimited connection was limited but they made it Unlimited. I can now browse all day and all night at a cheaper cost!",
			user: "Norah Kandie"
		},
		{
			remark: "My apartment lacked good 4G internet and had a lot of latency until I acquired Mnet Internet Which really worked fast for me allowing me to work from Home comfortably",
			user: "Vincent Amugenda"
		}
	]
}
